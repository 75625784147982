/*
 * ItemAnalysis Messages
 *
 * This contains all the text for the ItemAnalysis component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ItemAnalysis';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Thống kê theo sản phẩm',
  },
  supplier: {
    id: `${scope}.supplier`,
    defaultMessage: 'Nhà Cung Cấp',
  },
  item: {
    id: `${scope}.item`,
    defaultMessage: 'Sản Phẩm',
  },
  price: {
    id: `${scope}.price`,
    defaultMessage: 'Giá',
  },
  priceCalculation: {
    id: `${scope}.priceCalculation`,
    defaultMessage: 'Giá = Tổng Tiền / Số Lượng',
  },
  priceDescription: {
    id: `${scope}.priceDescription`,
    defaultMessage: 'Giá này là giá trung bình',
  },
  vat: {
    id: `${scope}.vat`,
    defaultMessage: 'VAT',
  },
  priceWithVat: {
    id: `${scope}.priceWithVat`,
    defaultMessage: 'Giá (sau VAT)',
  },
  quantity: {
    id: `${scope}.quantity`,
    defaultMessage: 'Số Lượng',
  },
  total: {
    id: `${scope}.total`,
    defaultMessage: 'Tổng Tiền (sau VAT)',
  },
  mom: {
    id: `${scope}.mom`,
    defaultMessage: 'MoM',
  },
  momDescription: {
    id: `${scope}.momDescription`,
    defaultMessage: "MoM% hiển thị 'Tỉ lệ % thay đổi theo tháng của Tổng Tiền (sau VAT)'",
  },
  selectItems: {
    id: `${scope}.selectItems`,
    defaultMessage: 'Chọn Sản Phẩm',
  },
  selectSuppliers: {
    id: `${scope}.selectSuppliers`,
    defaultMessage: 'Chọn Nhà Cung Cấp',
  },
  unavailableItem: {
    id: `${scope}.unavailableItem`,
    defaultMessage: 'Unavailable Item',
  },
});
