/**
 * ItemAnalysisPage
 *
 * TODO: consider re-implementing the state management.
 */

import * as React from 'react';

import { Dispatch, compose } from 'redux';
import { RawData, RootState, URLParameters } from './types';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import ItemAnalysis from 'components/ItemAnalysis';
import { Language } from 'types/schema';
import LoadingIndicator from 'components/LoadingIndicator';
import ParamsWrapper from 'components/ItemAnalysis/ParamsWrapper';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getParamObjectByLocationSearch } from 'utils/url';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { makeSelectLocale } from '../LanguageProvider/selectors';
import messages from './messages';
import moment from 'moment';
import reducer from './reducer';
import saga from './saga';
import selectItemAnalysisPage from './selectors';
import styled from 'styled-components';
import { updateItemAnalysisDataSubmit } from './actions';
import { updateParametersSubmit } from 'containers/ItemAnalysisPage/actions';

const Wrapper = styled.div`
  background: #f9fafc;
  width: calc(100% - 74pt);
  padding-bottom: 20px;
`;

interface OwnProps {
  itemanalysispage: any;
  updateParameter: ({ params, shouldUpdateURL }: { params: URLParameters; shouldUpdateURL: boolean }) => void;
  locale: Language;
}

interface Filter {
  id: string;
  name: string;
}

interface StateProps {}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps;

export class ItemAnalysisPage extends React.PureComponent<Props> {
  public componentDidMount() {
    const { yearMonth, selectedItems, selectedItemsName, selectedSuppliers, selectedSuppliersName } =
      getParamObjectByLocationSearch(this.props.location.search);
    this.props.updateParameter({
      params: {
        yearMonth: yearMonth || moment().format('YYYY-MM'),
        selectedItems: selectedItems ? decodeURIComponent(selectedItems).split(',') : [],
        selectedItemsName: selectedItemsName ? decodeURIComponent(selectedItemsName).split(',') : [],
        selectedSuppliers: selectedSuppliers ? decodeURIComponent(selectedSuppliers).split(',') : [],
        selectedSuppliersName: selectedSuppliersName ? decodeURIComponent(selectedSuppliersName).split(',') : [],
      },
      shouldUpdateURL: false,
    });
  }

  private updateParameters = (obj: object) => {
    const {
      itemanalysispage: {
        itemAnalysisPage: { params: currentParams },
      },
    } = this.props;
    const newParams = {
      ...currentParams,
      ...obj,
    };
    this.props.updateParameter({ params: newParams, shouldUpdateURL: true });
  };

  private getFilteredDate = (summaryData: RawData, params: URLParameters) => {
    const filteredData: RawData = { ...summaryData };
    const { summaries } = summaryData;
    filteredData.summaries = summaries.filter((summary) => {
      if (params.selectedItems.length === 0 && params.selectedSuppliers.length === 0) {
        return true;
      }
      if (
        params.selectedItems.length > 0 &&
        params.selectedSuppliers.length === 0 &&
        params.selectedItems.includes(summary.product.id)
      ) {
        return true;
      }
      if (
        params.selectedSuppliers.length > 0 &&
        params.selectedItems.length === 0 &&
        params.selectedSuppliers.includes(summary.supplier.id.toString())
      ) {
        return true;
      }
      if (
        params.selectedSuppliers.includes(summary.supplier.id.toString()) &&
        params.selectedItems.includes(summary.product.id)
      ) {
        return true;
      }
      return false;
    });
    return filteredData;
  };

  public getFilterList = (selectedFilterSet, param, selectedList, field) => {
    const {
      itemanalysispage: {
        itemAnalysisPage: {
          storeOrderItemsSummary: { node },
        },
      },
    } = this.props;
    return ((selectedFilters) =>
      [...node.summary.summaries.map((summary) => summary[field]), ...selectedFilters]
        .map((s) => ({
          id: s.id,
          name: s.name || '',
        }))
        .concat(selectedList)
        .reduce((result: Filter[], item) => (result.find((i) => i.id === item.id) ? result : [...result, item]), [])
        .sort((s1, s2) => s1.name.toLocaleLowerCase().localeCompare(s2.name.toLocaleLowerCase()))
        .filter((s) => !!s.name))((param || []).filter((item) => selectedFilterSet.has(item.id)));
  };

  public getSelectedList = (idList, nameList) =>
    idList.map((i, index) => ({
      id: i,
      name: nameList[index],
    }));

  public render() {
    const {
      itemanalysispage: { itemAnalysisPage },
    } = this.props;

    if (!itemAnalysisPage.storeOrderItemsSummary) {
      return <LoadingIndicator />;
    }

    const { selectedItems, selectedItemsName, acquiredItemList } = itemAnalysisPage.params;

    const summaryData = itemAnalysisPage.storeOrderItemsSummary.node.summary;
    const filteredDate = this.getFilteredDate(summaryData, itemAnalysisPage.params);
    const selectedItemSet = new Set(selectedItems);
    const itemList = this.getFilterList(
      selectedItemSet,
      acquiredItemList,
      this.getSelectedList(selectedItems, selectedItemsName),
      'product',
    );

    return (
      <div>
        <FormattedMessage {...messages.header}>
          {(title) => (
            <Helmet>
              <title>{title}</title>
              <meta name="description" content="Page for analysis per item" />
            </Helmet>
          )}
        </FormattedMessage>
        <Wrapper>
          <ParamsWrapper
            itemList={itemList}
            params={itemAnalysisPage.params}
            onUpdateParameters={this.updateParameters}
          />
        </Wrapper>
        <ItemAnalysis
          summaryData={filteredDate}
          params={itemAnalysisPage.params}
          isLoading={itemAnalysisPage.isLoading}
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  itemanalysispage: selectItemAnalysisPage(),
  locale: makeSelectLocale(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    onUpdateSupplierAnalysis: (data: URLParameters) => dispatch(updateItemAnalysisDataSubmit(data)),
    updateParameter: (params: { params: URLParameters; shouldUpdateURL: boolean }) =>
      dispatch(updateParametersSubmit(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({ key: 'itemAnalysisPage', reducer: reducer });
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'itemAnalysisPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(withRouter(ItemAnalysisPage));
