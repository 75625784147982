import { fork, take, call, put, select } from 'redux-saga/effects';
import {
  fetchItemAnalysisData as fetchItemAnalysisDataAction,
  updateParameters as updateParametersAction,
  setIsLoading as setIsLoadingAction,
} from './actions';
import { getStoreOrderItemsSummary } from 'utils/apollo';
import { push } from 'connected-react-router';
import moment from 'moment';

import selectItemAnalysisPage from './selectors';
import { getStoreWithRetry, getStore } from 'containers/MainLayout/saga';
import ActionTypes from './constants';
import { stringify } from 'utils/queryString';

function* fetchItemAnalysisData() {
  try {
    yield put(fetchItemAnalysisDataAction.request());
    yield put(setIsLoadingAction(true));
    const store = yield call(getStore);
    const itemAnalysis = yield select(selectItemAnalysisPage());
    const response = yield call(getStoreOrderItemsSummary, {
      queryInput: {
        storeId: store.id,
        yearMonth: itemAnalysis.itemAnalysisPage.params.yearMonth || moment().format('YYYY-MM'),
      },
    });
    if (!response.errors) {
      const summaries = response.node.summary.summaries;
      const itemList = summaries
        .filter(
          (summary, index, self) =>
            index ===
            self.findIndex(t => t.product.id === summary.product.id && t.product.name === summary.product.name),
        )
        .map(e => ({
          id: e.product.id,
          name: e.product.name,
        }));
      const supplierList = summaries
        .filter(
          (summary, index, self) =>
            index ===
            self.findIndex(t => t.supplier.id === summary.supplier.id && t.supplier.name === summary.supplier.name),
        )
        .map(e => ({
          id: e.supplier.id,
          name: e.supplier.name,
        }));
      yield put(fetchItemAnalysisDataAction.success({ res: response, itemList: itemList, supplierList: supplierList }));
    } else {
      yield put(fetchItemAnalysisDataAction.failure(response.errors));
    }
  } finally {
    yield put(setIsLoadingAction(false));
  }
}

function* updateParameters() {
  while (true) {
    const { payload } = yield take(ActionTypes.UPDATE_PARAMETERS_SUBMIT);
    yield put(updateParametersAction(payload.params));
    if (payload.shouldUpdateURL) {
      const newParams = {
        yearMonth: payload.params.yearMonth,
        selectedItems: payload.params.selectedItems.join(','),
        selectedItemsName: payload.params.selectedItemsName.join(','),
        selectedSuppliers: payload.params.selectedSuppliers.join(','),
        selectedSuppliersName: payload.params.selectedSuppliersName.join(','),
      };
      const newQueryString = stringify(newParams);
      yield put(
        push({
          search: newQueryString,
        }),
      );
    }
  }
}

function* initData() {
  yield call(getStoreWithRetry);
  yield call(fetchItemAnalysisData);
}

// Individual exports for testing
export default function* itemAnalysisPageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(initData);
  yield fork(updateParameters);
}
