/*
 *
 * ItemAnalysisPage reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  params: {
    yearMonth: '',
    selectedItems: [],
    selectedItemsName: [],
    selectedSuppliers: [],
    selectedSuppliersName: [],
  },
  acquiredItemList: [],
  acquiredSupplierList: [],
};

function itemAnalysisPageReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;

  switch (type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.FETCH_ITEM_ANALYSIS_DATA_SUCCESS:
    case ActionTypes.UPDATE_ITEM_ANALYSIS_DATA_SUCCESS:
      const allItems = [...state.acquiredItemList, ...payload.itemList];
      const itemList = allItems.filter(
        (item, index, self) => index === self.findIndex(t => t.id === item.id && t.name === item.name),
      );
      const allSuppliers = [...state.acquiredSupplierList, ...payload.supplierList];
      const supplierList = allSuppliers.filter(
        (supplier, index, self) => index === self.findIndex(t => t.id === supplier.id && t.name === supplier.name),
      );
      return {
        ...state,
        storeOrderItemsSummary: {
          ...payload.res,
        },
        acquiredItemList: itemList,
        acquiredSupplierList: supplierList,
      };
    case ActionTypes.UPDATE_PARAMETERS:
      return {
        ...state,
        params: {
          ...payload,
        },
      };
    case ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return state;
  }
}

export default itemAnalysisPageReducer;
