import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the itemAnalysisPage state domain
 */

const selectItemAnalysisPageDomain = (state: ApplicationRootState) => {
  return state ? state : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by ItemAnalysisPage
 */

const selectItemAnalysisPage = () =>
  createSelector(selectItemAnalysisPageDomain, substate => {
    return substate;
  });

export default selectItemAnalysisPage;
export { selectItemAnalysisPageDomain };
