import { defineMessages } from 'react-intl';

export const scope = 'app.components.Empty';

export default defineMessages({
  noData: {
    id: `${scope}.noData`,
    defaultMessage: 'No Data',
  },
});
