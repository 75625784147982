/**
 *
 * ItemAnalysis
 *
 */

import * as React from 'react';

import { ColumnForTable, RawData, Summary, TableData, URLParameters } from 'containers/ItemAnalysisPage/types';
import { Table, Tooltip } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import styled, { createGlobalStyle } from 'styled-components';

import Empty from '../Empty';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import formatMoney from 'utils/formatMoney';
import messages from './messages';
import moment from 'moment';
import generateProductSlug from 'utils/generateProductSlug';

interface OwnProps {
  summaryData: RawData;
  params: URLParameters;
  isLoading: boolean;
}

const TableWrapper = styled.div`
  width: 100%;
`;
const StyledTable = styled(Table)`
  && table {
    border-bottom-left-radius: 4px;
    border-collapse: separate;
    border-spacing: 0px;
  }

  &&& th,
  &&& td {
    color: #595959;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
  .ant-table-thead {
    tr > th {
      font-weight: bold;
      background-color: #f6ffed;
    }
  }
  .ant-table-tbody {
    .plus {
      color: #389e0d;
    }
    .minus {
      color: #cf1322;
    }
    background-color: white;
    tr > td {
      padding: 10px 16px;
    }
    tr:hover {
      background-color: initial;
    }
    tr:last-child {
      border-bottom: 1px solid #e8e8e8;
      td:first-child {
        border-bottom-left-radius: 4px;
      }
      td:last-child {
        border-bottom-right-radius: 4px;
      }
    }
    td:nth-child(2) {
      font-weight: bold;
    }
    td:nth-child(3) {
      text-align: right;
    }
    td:nth-child(5) {
      text-align: right;
    }
    td:nth-child(7) {
      text-align: right;
      font-weight: bold;
    }
    td:nth-child(8) {
      background-color: #f5f5f5;
    }
    td:nth-child(9) {
      background-color: #f5f5f5;
      text-align: right;
    }
    [data-row-key='0'] {
      td {
        border-top: 1px solid #e8e8e8;
      }
    }
  }
`;

const StyledInfo = styled(LegacyIcon)`
  margin-left: 5px;
  color: white;
  background: #b4b4b4;
  border-radius: 50%;
`;

const GlobalStyle = createGlobalStyle`
  .tooltip {
    color: #595959;
    .ant-tooltip-arrow {
      border-top-color: white;
    }
    .ant-tooltip-inner {
      background: white;
      color: #595959
    }
  }
`;

const formatLastMonth = (summary: Summary): React.ReactElement => {
  return <span>{formatMoney(summary.lastMonthTotalGrossPrice)}đ</span>;
};

const momPercent = (summary: Summary): number | undefined => {
  if (summary.lastMonthTotalGrossPrice === 0) {
    return undefined;
  }
  const difference = summary.totalGrossPrice - summary.lastMonthTotalGrossPrice;
  return Math.round((difference / summary.lastMonthTotalGrossPrice) * 1000) / 10;
};

const formatForMom = (summary: Summary): string | React.ReactElement => {
  const mom = momPercent(summary);
  if (mom === undefined) {
    return '-';
  }

  return mom === 0 ? (
    <span>{mom}%</span>
  ) : mom > 0 ? (
    <span className="plus">+{mom}%</span>
  ) : (
    <span className="minus">{mom}%</span>
  );
};

const StyledLink = styled(Link)`
  color: #595959;
`;

const formatProduct = (product: Summary['product']): React.ReactElement => {
  const { name, supplierInternalCode } = product;
  const lang = localStorage.getItem('lang') || 'vi';
  if (!name) {
    return <FormattedMessage {...messages.unavailableItem} />;
  }

  return (
    <StyledLink title={name} to={`/products/${generateProductSlug(name, supplierInternalCode, lang)}`}>
      {name.trim()}
    </StyledLink>
  );
};

const createDataset = (summary: RawData, params: URLParameters) => {
  const columns: ColumnForTable[] = [
    {
      title: <FormattedMessage {...messages.supplier} />,
      dataIndex: 'supplier',
      key: 'supplier',
      sorter: (a, b) => a.supplier && b.supplier && a.supplier.toLowerCase().localeCompare(b.supplier.toLowerCase()),
      width: 118,
    },
    {
      title: <FormattedMessage {...messages.item} />,
      dataIndex: 'displayedProduct',
      key: 'displayedProduct',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        if (a.disabled && b.disabled) {
          return 0;
        }
        if (a.disabled) {
          return 1;
        }
        if (b.disabled) {
          return -1;
        }
        return a.productName.toLowerCase().localeCompare(b.productName.toLowerCase());
      },
      width: 165,
    },
    {
      title: (
        <span>
          <FormattedMessage {...messages.price} />
          <Tooltip
            title={
              <>
                <FormattedMessage {...messages.priceCalculation} />
                <br />
                <FormattedMessage {...messages.priceDescription} />
              </>
            }
            overlayClassName="tooltip"
          >
            <StyledInfo type="info-circle" />
          </Tooltip>
        </span>
      ),
      dataIndex: 'price',
      key: 'price',
      width: 113,
    },
    {
      title: <FormattedMessage {...messages.vat} />,
      dataIndex: 'VAT',
      key: 'VAT',
      width: 67,
    },
    {
      title: <FormattedMessage {...messages.priceWithVat} />,
      dataIndex: 'displayedPriceInclVAT',
      key: 'displayedPriceInclVAT',
      sorter: (a, b) => a.priceInclVAT - b.priceInclVAT,
      width: 116,
    },
    {
      title: <FormattedMessage {...messages.quantity} />,
      dataIndex: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
      key: 'quantity',
      width: 95,
      render: (quantity, item) => (
        <span>
          {quantity} {item.uomLocal}
        </span>
      ),
    },
    {
      title: <FormattedMessage {...messages.total} />,
      dataIndex: 'displayedTotalInclVAT',
      key: 'displayedTotalInclVAT',
      defaultSortOrder: '',
      sorter: (a, b) => a.totalInclVAT - b.totalInclVAT,
      width: 134,
    },
    {
      title: (
        <span>
          <FormattedMessage {...messages.mom} />%
          <Tooltip overlayClassName="tooltip" title={<FormattedMessage {...messages.momDescription} />}>
            <StyledInfo type="info-circle" />
          </Tooltip>
        </span>
      ),
      dataIndex: 'displayedMoM',
      key: 'MoM',
      defaultSortOrder: '',
      sorter: (a, b) => {
        if (a.MoM === undefined) {
          return -1;
        }
        if (b.MoM === undefined) {
          return 1;
        }
        return a.MoM - b.MoM;
      },
      width: 98,
    },
    {
      title: moment(params.yearMonth).subtract(1, 'month').format('MM/YYYY'),
      dataIndex: 'displayedLastMonth',
      key: 'displayedLastMonth',
      sorter: (a, b) => a.lastMonth - b.lastMonth,
      width: 135,
    },
  ];

  const tableData: TableData[] = summary.summaries.map((s) => {
    const priceInclVAT = s.quantity !== 0 ? s.totalGrossPrice / s.quantity : 0;
    return {
      id: `${s.supplier.id}:${s.product.id}}`,
      supplier: s.supplier.name.trim(),
      displayedProduct: formatProduct(s.product),
      productName: !!s.product.name ? s.product.name.trim() : '',
      productId: s.product.id,
      disabled: !s.product.name,
      price: `${formatMoney(s.totalNetPrice / s.quantity)}đ`,
      VAT: s.product.vat !== null ? `${String(s.product.vat)}%` : '-',
      priceInclVAT: s.quantity !== 0 ? priceInclVAT : 0,
      displayedPriceInclVAT: `${formatMoney(priceInclVAT)}đ`,
      quantity: s.quantity,
      uom: s.product.uomLocal,
      // `${s.quantity} ${s.product.uomLocal || ''}`,

      displayedTotalInclVAT: `${formatMoney(s.totalGrossPrice)}đ`,
      totalInclVAT: s.totalGrossPrice,
      displayedMoM: formatForMom(s),
      MoM: momPercent(s),
      displayedLastMonth: formatLastMonth(s),
      lastMonth: s.lastMonthTotalGrossPrice,
    };
  });

  return { columns: columns, tableData: tableData };
};

const Wrapper = styled.div`
  position: relative;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
`;

const ItemAnalysis: React.FC<OwnProps> = ({ summaryData, params, isLoading }: OwnProps) => {
  const { columns, tableData } = createDataset(summaryData, params);
  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <TableWrapper>
          <div style={{}}>
            <StyledTable
              columns={columns as any}
              dataSource={tableData}
              bordered
              scroll={{ y: 'calc(100vh - 380px)', x: 1040 }}
              pagination={false}
              rowKey="id"
              locale={{
                emptyText: <Empty />,
              }}
            />
          </div>
        </TableWrapper>
        {isLoading && (
          <LoadingWrapper>
            <LoadingIndicator />
          </LoadingWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default ItemAnalysis;
