/*
 *
 * ItemAnalysisPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';
import { URLParameters } from './types';

import ActionTypes from './constants';

export const fetchItemAnalysisData = createAsyncAction(
  ActionTypes.FETCH_ITEM_ANALYSIS_DATA,
  ActionTypes.FETCH_ITEM_ANALYSIS_DATA_SUCCESS,
  ActionTypes.FETCH_ITEM_ANALYSIS_DATA_FAILURE,
)<void, {}, Error>();
export const updateItemAnalysisDataSubmit = (data: any) => {
  return action(ActionTypes.FETCH_ITEM_ANALYSIS_DATA_SUBMIT, data);
};
export const updateItemAnalysisData = createAsyncAction(
  ActionTypes.UPDATE_ITEM_ANALYSIS_DATA,
  ActionTypes.UPDATE_ITEM_ANALYSIS_DATA_SUCCESS,
  ActionTypes.UPDATE_ITEM_ANALYSIS_DATA_FAILURE,
)<void, {}, Error>();

export const updateParametersSubmit = (data: { params: URLParameters; shouldUpdateURL: boolean }) =>
  action(ActionTypes.UPDATE_PARAMETERS_SUBMIT, data);
export const updateParameters = (data: URLParameters) => action(ActionTypes.UPDATE_PARAMETERS, data);

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const setIsLoading = (data: boolean) => action(ActionTypes.SET_IS_LOADING, data);
