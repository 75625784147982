import '../../utils/strings';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Select } from 'antd';
import { ItemList, URLParameters } from 'containers/ItemAnalysisPage/types';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import messages from './messages';
import moment from 'moment';
import styled from 'styled-components';

interface Props {
  itemList: ItemList;
  params: URLParameters;
  onUpdateParameters: (obj) => void;
  form: WrappedFormUtils;
}
const FormWrapper = styled.div`
  z-index: 80;
  width: 100%;
  background-color: #f9fafc;
  display: flex;
  gap: 20px;
  padding-right: 20pt;
  align-items: center;
  .ant-form-item {
    width: 100%;
    margin: 0 15px 0 0;
  }
  .ant-select {
    width: 100%;
  }

  && .ant-select-selection__choice {
    background: #40a9ff;
  }
  .ant-col {
    width: 100%;
  }
`;

const YearMonth = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  min-width: 170px;
  flex-shrink: 0;
`;
const { Option } = Select;

const ParamsWrapper = (props: Props) => {
  const { getFieldDecorator } = props.form;
  const { itemList, params, onUpdateParameters } = props;
  const itemIdList = itemList.map((i) => i.id);
  const onChange = (value, list, key) => {
    const nameList = list.filter(({ id }) => value.includes(id.toString())).map((i) => i.name);
    onUpdateParameters({
      [key]: value,
      [`${key}Name`]: nameList,
    });
  };

  return (
    <FormWrapper>
      <Form.Item style={{ flexGrow: 1 }}>
        {getFieldDecorator('selected-items', {
          initialValue: params.selectedItems,
        })(
          <Select
            mode="multiple"
            placeholder={<FormattedMessage {...messages.selectItems} />}
            onChange={(value) => onChange(value, itemList, 'selectedItems')}
            filterOption={(input, option) =>
              option?.props?.children
                ? (option?.props?.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                : false
            }
            size="large"
            style={{ width: '100%' }}
          >
            {itemList.map((i) => (
              <Option
                value={i.id}
                key={i.id}
                style={itemIdList.includes(i.id) ? { display: 'block' } : { display: 'none' }}
              >
                {i.name}
              </Option>
            ))}
          </Select>,
        )}
      </Form.Item>
      <YearMonth>
        <LeftOutlined
          className="left"
          onClick={() =>
            onUpdateParameters({
              yearMonth: moment(params.yearMonth).subtract(1, 'month').format('YYYY-MM'),
            })
          }
        />
        <p className="date">{moment(params.yearMonth).format('MMMM YYYY').capitalizeFirstChar()}</p>
        <RightOutlined
          className="right"
          onClick={() =>
            onUpdateParameters({
              yearMonth: moment(params.yearMonth).add(1, 'month').format('YYYY-MM'),
            })
          }
        />
      </YearMonth>
    </FormWrapper>
  );
};

export default Form.create<Props>()(ParamsWrapper);
