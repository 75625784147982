/*
 *
 * ItemAnalysisPage constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/ItemAnalysisPage/DEFAULT_ACTION',
  FETCH_ITEM_ANALYSIS_DATA_SUBMIT = 'app/ItemAnalysisPage/FETCH_ITEM_ANALYSIS_DATA_SUBMIT',
  FETCH_ITEM_ANALYSIS_DATA = 'app/ItemAnalysisPage/FETCH_ITEM_ANALYSIS_DATA',
  FETCH_ITEM_ANALYSIS_DATA_SUCCESS = 'app/ItemAnalysisPage/FETCH_ITEM_ANALYSIS_DATA_SUCCESS',
  FETCH_ITEM_ANALYSIS_DATA_FAILURE = 'app/ItemAnalysisPage/FETCH_ITEM_ANALYSIS_DATA_FAILURE',
  UPDATE_ITEM_ANALYSIS_DATA = 'app/ItemAnalysisPage/UPDATE_ITEM_ANALYSIS_DATA',
  UPDATE_ITEM_ANALYSIS_DATA_SUCCESS = 'app/ItemAnalysisPage/UPDATE_ITEM_ANALYSIS_DATA_SUCCESS',
  UPDATE_ITEM_ANALYSIS_DATA_FAILURE = 'app/ItemAnalysisPage/UPDATE_ITEM_ANALYSIS_DATA_FAILURE',
  UPDATE_PARAMETERS_SUBMIT = 'app/ItemAnalysisPage/UPDATE_PARAMETERS_SUBMIT',
  UPDATE_PARAMETERS = 'app/ItemAnalysisPage/UPDATE_PARAMETERS',
  SET_IS_LOADING = 'app/ItemAnalysisPage/SET_IS_LOADING',
}

export default ActionTypes;
